import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Dashboard from './Dashboard';
import InvestmentsList from './InvestmentsList';
import Navbar from './Navbar';
import PlansList from './PlansList';
import AddNewPlan from './NewPlan';
import UserList from './UsersList';
import ManipulateBalance from './Manipulatebalance';
import Authentication from './Authenticate';



import ShipmentList from './Logistics/ShipmentList';
import UpdateShipment from './Logistics/UpdateShipment';
import CreateShipment from './Logistics/CreateShipment';
import UpdateDe from './Logistics/UpdateDetails';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { DarkMode } from '@mui/icons-material';
import GenReciept from './Logistics/GenReciept';

//check path for api
let pat = window.location.pathname;
function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<GenReciept />} />


        </Routes>
      </Router>
    </>
  );
}

export default App;


// {
//   "id": "83d228a8-4cb7-469e-a326-4ac7ba502d41",
//   "first_name": "Bernardo ",
//   "last_name": "Woodman ",
//   "user_name": "ss",
//   "email": "sss@gmail.com",
//   "is_email_verified": false,
//   "affiliate_wallet": "",
//   "password": "123456",
//   "phone": "",
//   "address": "",
//   "city": "",
//   "state": "",
//   "country": "",
//   "zip": "",
//   "security_pin": "0000",
//   "balance": "12",
//   "status": "Active",
//   "public_address": "0x3c47d6ed0c36c27390fa912c8c398bb7593c76aa",
//   "private_key": "0xx",
//   "created_at": "2023-11-28 08:35:49.284637874 UTC",
//   "updated_at": "2023-11-28 08:35:49.284654500 UTC"
// }
